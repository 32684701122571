<template>
  <div style="display:inline-block;" @click="open">
    <slot></slot>
    <a-modal
      :title="title"
      v-model="show"
      :maskClosable="false"
      :width="500"
      okText="确定导入"
      @ok="handleConfirm"
      @cancel="handleCancel"
    >
      <div>
        <a-upload-dragger
          name="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :customRequest="customRequest"
          :beforeUpload="beforeUpload"
          :multiple="false"
          :showUploadList="false"
          @change="handleChange"
        >
          <div v-if="fileList.length===0">
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">点击选择文件或者将文件拖入此处</p>
          </div>
          <div v-else title="点击重新选择文件">
            <p class="ant-upload-drag-icon">
              <a-icon type="file-excel" />
            </p>
            <p class="ant-upload-text">{{fileName}}</p>
          </div>
        </a-upload-dragger>

        <div style="margin-top:10px;font-size:16px;">
          <a v-if="templateUrl" :href="templateUrl" target="_bank">
            <a-icon type="paper-clip" />下载模板
          </a>
        </div>
        <a-alert style="margin-top:10px;" v-show="remark" :message="`提示：${remark}`" type="warning" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import qs from "qs";
// 引入xlsx
var XLSX = require("xlsx");
export default {
  props: {
    title: {
      type: String,
      default: "导入"
    },
    templateUrl: {
      type: String
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    beforeOpen: {
      type: Function
    },
    remark:{
      type:String,
      default() {
        return null;
      }

    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      fullscreenLoading: false, // 加载中
      imFile: "", // 导入文件el
      errorMsg: "", // 错误信息内容,
      show: false,
      jsonData: [],
      checked: 1,
      types: [],
      fileList: [],
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 16,
            offset: 0
          }
        }
      },
      formItemLayout: {},
      fileName: ""
    };
  },
  // computed: {
  //     show: {
  //         get: function (){
  //             return this.visible;
  //         },
  //         set: function () {}
  //     }
  // },
  mounted() {
    // this.imFile = document.getElementById("imFile");
  },
  destroyed() {
    // localStorage.clear("isQuestion")
  },
  created() {},
  methods: {
    open() {
      if (this.beforeOpen && !this.beforeOpen()) {
        return;
      }
      this.show = true;
    },
    customRequest() {},
    beforeUpload(file) {
      this.fileList = [file];
      this.fileName = file.name;
      return false;
    },
    handleChange(info, fileList) {
      // 导入excel
      this.fullscreenLoading = true;
      if (!info.file) {
        this.fullscreenLoading = false;
        return;
      }
      var f = info.file;
      var reader = new FileReader();
      let $t = this;
      reader.onload = function(e) {
        var data = e.target.result;

        if ($t.rABS) {
          $t.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64"
          });
        } else {
          $t.wb = XLSX.read(data, {
            type: "binary"
          });
        }
        $t.jsonData = XLSX.utils.sheet_to_json(
          $t.wb.Sheets[$t.wb.SheetNames[0]]
        );
        // $t.dealFile(json); // analyzeData: 解析导入数据
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },

    fixdata: function(data) {
      // 文件流转BinaryString
      var o = "";
      var l = 0;
      var w = 10240;
      for (; l < data.byteLength / w; ++l) {
        o += String.fromCharCode.apply(
          null,
          new Uint8Array(data.slice(l * w, l * w + w))
        );
      }
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },
    handleConfirm() {
      if (this.fileList.length == 0) {
        this.$message.error("请选择导入的文件");
        return;
      }

      if (this.verifyErrorExcel()) {
        this.$notification.error({
          message: "导入模板错误",
          description: `请选择正确的导入模板`
        });
        return;
      }

      let dataList = JSON.parse(JSON.stringify(this.jsonData));
      let columns = this.columns,
        checked = true,
        verify = true;
      if (columns && columns.length) {
        //模板验证
        if (dataList.length === 0) {
          this.$notification.error({
            message: "导入数据错误",
            description: `导入内容不可为空`
          });
          return;
        }
        //内容验证
        dataList = dataList.map((item, index) => {
          let obj = {};
          columns.map(column => {
            const { key, title, transform, type, required,maxLength } = column;
            if (required && !item[title]) {
              this.$notification.error({
                message: "导入数据错误",
                description: `第 ${index + 1} 行【${title}】不可为空！`
              });
              checked = false;
            }
            if(item[title]&&maxLength&&item[title].length>Number(maxLength)){
              this.$notification.error({
                message: "导入数据错误",
                description: `第 ${index + 1} 行【${title}】最大长度不可超过${maxLength}字符！`
              });
              checked = false;
            }
            let value = item[title] ? item[title] : "";
            if (type === "number" && typeof Number(value) !== "number") {
              value = 0;
            } else if (type === "string") {
              value = value.toString().trim();
            } else if (value && type === "date") {
              let date = this.$moment(value);
              if (date._isValid && typeof value === "string") {
                value = date.format("YYYY-MM-DD");
              } else {
                this.$notification.error({
                  message: "导入数据错误",
                  description: `第 ${index + 1} 行【${title}】日期格式错误！`
                });
                checked = false;
              }
            }
            obj[key] = value;
            if (transform && typeof transform === "function") {
              obj[key] = transform(value);
            }
          });
          return obj;
        });
      }
      if (checked) {
        this.$emit("onImport", dataList);
        this.handleCancel();
      }
    },
    handleCancel() {
      this.show = false;
      this.fileList = [];
      this.jsonData = [];
    },
    /**
     * 验证模板的是否错误
     */
    verifyErrorExcel: function () {
      let {columns, wb} = this;
      let sheet = wb.Sheets[wb.SheetNames[0]];
      let heads = [];
      Object.keys(sheet).forEach(key => {
        let head = sheet[key]["v"];
        if (head) {
          heads.push(head);
        }
      });
      columns.forEach(item => {
        if (!heads.includes(item.title)) {
          console.log("丢失的列",item.title);
        }
      })
      return columns.some(item => !heads.includes(item.title));
    }
  }
};
</script>