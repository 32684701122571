<template>
    <a-modal
            :title="id?'参数修改':'参数新增'"
            :visible="visible"
            width="30%"
            :confirm-loading="confirmLoading"
            ok-text="确认"
            cancel-text="取消"
            @ok="handleConfirm"
            @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item label="电梯类型" v-bind="formItemLayout">
                <a-radio-group v-model="type">
                    <a-radio value="1">
                        基础价格
                    </a-radio>
                    <a-radio value="2">
                        装潢、功能价格
                    </a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="电梯类型" v-bind="formItemLayout" v-if="type==1">
                <a-select v-decorator="['elevatorType', {rules: [{ required: true, message: '电梯类型'}]}]"
                          placeholder="请选择电梯类型" allowClear>
                    <a-select-option v-for="item in elevator" :key="item.key"
                                     :value="item.key">{{item.val}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="分类" v-bind="formItemLayout" v-if="type==2">
                <a-select v-decorator="['categoryType', {rules: [{ required: true, message: '请选择分类'}]}]"
                          placeholder="请选择分类" allowClear @change="select">
                    <a-select-option v-for="it in category" :key="it.key"
                                     :value="it.key">{{it.val}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="参数类型" v-bind="formItemLayout" v-if="type==2&&this.form.getFieldDecorator('categoryId')">
                <a-select v-decorator="['categoryId', {rules: [{ required: true, message: '请选择分类'}]}]"
                          placeholder="请选择分类" allowClear>
                    <a-select-option v-for="it in categoryList" :key="it.id"
                                     :value="it.id">{{it.name}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="载重/角度" v-bind="formItemLayout" v-if="type==1">
                <a-input
                        placeholder="请输入载重/角度"
                        v-decorator="['field1',
            {rules: [{ required: true,max:20, message: '载重/角度必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="速度/高度" v-bind="formItemLayout" v-if="type==1">
                <a-input
                        placeholder="请输入速度/高度"
                        v-decorator="['field3',
            {rules: [{ required: true,max:20, message: '速度/高度必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="层站/宽度" v-bind="formItemLayout" v-if="type==1">
                <a-input
                        placeholder="请输入层站/宽度"
                        v-decorator="['field2',
            {rules: [{ required: true,max:20, message: '层站/宽度必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="名称" v-bind="formItemLayout" v-if="type==2">
                <a-input
                        placeholder="请输入名称"
                        v-decorator="['name',
            {rules: [{ required: true,max:20, message: '名称必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="描述" v-bind="formItemLayout" v-if="type==2">
                <a-input
                        placeholder="请输入描述"
                        v-decorator="['description']"
                />
            </a-form-item>
            <a-form-item label="价格" v-bind="formItemLayout">
                <a-input
                        placeholder="请输入价格"
                        v-decorator="['price',
            {rules: [{ required: true,max:20, message: '价格必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="图片" v-bind="formItemLayout" v-if="type==2">
                <a-upload
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :file-list="fileList"
                        :beforeUpload="beforeUpload"
                        @change="handleChange"
                >
                    <a-icon :type="loading ? 'loading' : 'plus'" v-if="fileList.length==0"/>
                    <div v-if="fileList.length==0">
                        <div class="ant-upload-text">
                            图片上传
                        </div>
                    </div>
                </a-upload>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import axios from "axios";
    import {add,update,category} from '@/api/model'
    export default {
        props:['visible'],
        data(){
            return {
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 6
                    },
                    wrapperCol: {
                        span: 18
                    }
                },
                maxLength:1000,
                confirmLoading: false,
                elevator:[
                    {val:'客梯',key:0},
                    {val:'货梯',key:1},
                    {val:'扶梯',key:2},
                ],
                category:[
                    {val:'功能参数',key:0},
                    {val:'装潢参数',key:1},
                ],
                id:'',
                type:'2',
                loading: false,
                imageUrl: '',
                fileList:[],
                categoryList:[],
                categoryType:''
            }
        },
        computed: {
            show: {
                get: function (){
                    return this.visible;
                },
                set: function () {}
            }
        },
        watch:{
            visible(val){
                if(val) {
                    this.load()
                }
            }
        },
        methods:{
            select(e) {
                this.categoryType = e
                this.load()
            },
            load() {
                const parms ={
                    current:1,
                    size:-1,
                    categoryType:this.categoryType
                }
                category(parms).then((res) => {
                    this.categoryList=res.records
                })
            },
            handleChange(info) {
                if (info.file.status === 'uploading') {
                  this.loading = false;
                  this.fileList =[
                    {
                      uid: info.file.uid,
                      name: info.file.name,
                      status: info.file.status,
                    }
                  ]
                } else if(info.file.status =="removed"){
                  this.fileList =[]
                }
            },
            beforeUpload(file) {
              let param = new FormData();
              param.append('file',file);
              let config = {
                headers:{
                  'Content-Type':'multipart/form-data',
                  'Authorization': localStorage.getItem('token')
                }
              };
              axios.post('/api' +'/file/upload',param,config)
                  .then(response=>{
                    console.log(response.data);
                   this.$nextTick(()=>{
                     this.fileList[0].url = response.data.data
                     this.imageUrl = response.data.data
                   })
                  })
            },
            setFormValues(record) {
                Object.keys(record).forEach(key => {
                    this.form.getFieldDecorator(key);
                    let obj = {};
                    if(key == "imagesUrl") {
                      if(record[key]){
                        this.fileList.push(
                            {
                              uid: '-3',
                              name: 'image.png',
                              status: 'done',
                              url: record[key]
                            }
                        )
                      } else {
                        this.fileList = []
                      }
                    } else if(key == "elevatorType") {
                      if(record[key]==null){
                        this.type = '2'
                      }else {
                        this.type = '1'
                      }
                      obj[key] = record[key];
                      this.form.setFieldsValue(obj);
                    } else if(key == "id") {
                        this.id = record[key]
                    }else {
                        obj[key] = record[key];
                        this.form.setFieldsValue(obj);
                    }
                });
            },
            handleConfirm(e) {
                this.confirmLoading = true;
                this.form.validateFields(async (err, values) => {
                    this.confirmLoading = false;
                    if(!err) {
                      if(this.type=='2'){
                          values.imagesUrl= this.imageUrl
                      }
                        if (!this.id) {
                            add(values).then((res) => {
                                this.imageUrl=''
                                this.fileList=[]
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }else {
                            values.id = this.id
                            update(values).then((res) => {
                                this.id = ''
                                this.imageUrl=''
                                this.fileList=[]
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }
                    }
                });
            },
            handleCancel() {
                this.$emit('close')
                this.id=''
                this.imageUrl=''
                this.loading = false
                this.fileList=[]
                this.form.resetFields()
            }
        }
    }
</script>

<style scoped>

    .avatar-uploader > .ant-upload {
        width: 128px;
        height: 128px;
    }
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

</style>