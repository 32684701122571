const columns = [
        {
            title: '#',
            width:90,
            scopedSlots: {
                customRender: 'serial'
            }
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
        },
        {
            title: '类型',
            dataIndex: 'elevatorType',
            key: 'elevatorType',
            scopedSlots: { customRender: 'elevatorType' },
        },
        {
            title: '价格',
            dataIndex: 'price',
            key: 'price',
            ellipsis: true,
        },
        {
            title: '图片',
            dataIndex: 'imagesUrl',
            key: 'imagesUrl',
            scopedSlots: { customRender: 'imagesUrl' },
        },
        {
            title: '描述',
            key: 'description',
            dataIndex: 'description',
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            scopedSlots: { customRender: 'action' },
        },
    ];
const importColumns = [ {
    title: '电梯类型',
    key: 'elevatorType',
    type: "string",
    required: true,
    transform: (text) => {
        switch (text) {
            case '客梯':
                return 0
            case '货梯':
                return 1
            case '扶梯':
                return 2
            default:
                return text
        }
    }
}, {
    title: '载重/角度',
    key: 'field1',
    type: "string",
    required: true
}, {
    title: '层高/宽度',
    key: 'field2',
    type: "string",
    required: true
}, {
    title: '速度/高度',
    key: 'field3',
    type: "string",
    required: true
}, {
    title: '价格',
    key: 'price',
    type: "string",
    required: false,
}];
export {
    columns,
    importColumns
}
