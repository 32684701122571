<template>
    <div class="container">
        <a-row>
            <a-form :layout="formLayout">
                <a-form-item
                        label="名称"
                >
                    <a-input placeholder="名称模糊查询" v-model="searchForm.name"/>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="search">查询</a-button>
                    <a-button style="margin-left: 20px" @click="reset">重置</a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-row style="padding: 12px 0 0 12px" type="flex">
            <a-button type="primary" @click="addOrEdite(null)" style="margin-right: 24px">新增</a-button>
            <import-modal-lite
                templateUrl="https://www.shenghuaapp.com/files/template/电梯价格表.xlsx"
                @onImport="handleImport"
                :columns="importColumns"
                title="价格表导入"
            >
              <a-button ref="equipment">
                <a-icon type="upload" />导入
              </a-button>
            </import-modal-lite>
        </a-row>
        <br/>
        <a-table
                size="small"
                bordered
                rowKey="id"
                :columns="columns"
                :pagination="false"
                :loading="loading"
                :dataSource="dataList">
            <span slot="serial" slot-scope="text, record, index">
                {{ index + 1 }}
            </span>
            <span slot="imagesUrl" slot-scope="text, record">
                   <span class="txt-danger">{{text||'无'}}</span>
            </span>
            <span slot="elevatorType" slot-scope="text, record">
                   <span class="txt-danger">{{tranForm(text)||record.categoryName}}</span>
            </span>
            <span slot="imagesUrl" slot-scope="text, record">
              <viewer :images="photo">
                        <img
                            v-for="(src,index) in photo"
                            :src="text"
                            :key="index"
                            width="25"
                        >
              </viewer>
            </span>
            <span slot="action" slot-scope="text">
                <a @click="addOrEdite(text)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm
                        title="是否同意删除？"
                        @confirm="remove(text.id)"
                        okText="是"
                        cancelText="否"
                        placement="topRight"
                >
                    <a class="txt-danger" href="javascript:;">删除</a>
                </a-popconfirm>
            </span>
        </a-table>
        <br/>
      <Pagination
          :total="pagination.total"
          :pageSize="pagination.size"
          :current="pagination.current"
          @change="onPageChange"
          @showSizeChange="onPageSizeChange"
      />
        <form-modal
         ref="FormModal"
         :visible="visible"
         @close="handcanle"
         @confirm="handleConfirm"
        />
    </div>
</template>

<script>
    import {columns,importColumns} from "./module";
    import FormModal from "./module/formModal"
    import {list,del,load} from '@/api/model'
    import Pagination from "../../components/Pagination";
    import importModalLite from "@/components/ImportModalLite";
    export default {
        components:{FormModal,Pagination,importModalLite},
        data(){
            return {
                visible: false,
                columns,
                importColumns,
                formLayout:'inline',
                loading: false,
                searchForm:{},
                pagination: {
                  current:1,
                  size: 10
                },
                dataList:[],
                photo:['']
            }
        },
        created() {
            this.loadList()
        },
        methods:{
            tranForm(text) {
                switch(text) {
                    case 0:
                        return '客梯'
                        break
                    case 1:
                        return '货梯'
                        break
                    case 2:
                        return '扶梯'
                        break
                }
            },
            tansName(text) {
                switch(text) {
                    case 0:
                        return '功能参数'
                        break
                    case 1:
                        return '装潢参数'
                        break
                }
            },
            search() {
                this.loadList()
            },
            reset(){
                this.searchForm ={}
                this.loadList()
            },
            loadList() {
              this.loading = true
              const params = {
                ...this.searchForm,
                current:this.pagination.current,
                size: this.pagination.size
              }
                list(params).then((res)=>{
                  this.loading = false
                    this.dataList = res.records
                    const pagination = { ...this.pagination }
                    pagination.total = Number(res.total)
                    pagination.pageSize = Number(10)
                    this.pagination = pagination
                })
            },
          onPageChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.size = pageSize
            this.loadList()
          },
          onPageSizeChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.size = pageSize
            this.loadList()
          },
          addOrEdite(row) {
              console.log(row)
              this.visible = true
              if(row){
                  this.$refs.FormModal.setFormValues(row)
              }
          },
          remove(id) {
            del({id:id}).then((res) =>{
                this.loadList()
            })
          },
          handcanle() {
              this.visible = false
          },
          handleConfirm() {
              this.loadList()
          },
          handleImport(data) {
            load(data).then((res)=>{
              this.$message.success('操作成功');
              this.loadList()
            })
          }
        }
    }
</script>

<style scoped>
</style>